<template>
  <base-wrapper>
    <div class="space-y-4">
      <div class="flex items-center justify-end gap-x-2">
        <office-select-search
          :shadow="false"
          v-model="filter.office"
          @change="onFilter"
        />
        <role-select-search
          :shadow="false"
          v-model="filter.role"
          @change="onFilter"
        />
        <base-input
          :shadow="false"
          placeholder="Cari Nama atau Email"
          type="search"
          debounce
          v-on:native-input="onFilter"
          v-model="filter.search"
        />
        <base-button to="users/tambah-user">Tambah User</base-button>
      </div>
      <datatable
        :columns="columns"
        :scroll-x="false"
        :total="users.meta.page.total"
        :perPage="users.meta.page.perPage"
        :currentPage="users.meta.page.currentPage"
        :meta="users.meta"
        cursor
        @pagechanged="onPageChanged"
      >
        <template v-slot:tbody="{ classes }">
          <tr
            v-for="user in users.data"
            :key="user.id"
            :class="[classes.tr, 'bg-white hover:bg-green-100']"
            @click="onOpenDetail(user)"
          >
            <td :class="[classes.td, 'cursor-pointer']">
              <div class="space-y-1">
                <p class="font-bold text-gray-900">
                  {{ user.attributes.name }}
                </p>
                <p class="text-xs">{{ user.attributes.email }}</p>
              </div>
            </td>
            <td :class="[classes.td, 'cursor-pointer']">
              {{
                getSingleIncludedType('user', users, user).attributes
                  .current_role
              }}
            </td>
            <td :class="[classes.td, 'cursor-pointer']">
              <div class="flex items-center gap-x-2">
                <span>{{
                  getSingleIncludedType(
                    'office',
                    users,
                    getSingleIncludedType('user', users, user)
                  ).attributes.name
                }}</span>
                <base-badge size="sm">{{
                  getSingleIncludedType(
                    'office',
                    users,
                    getSingleIncludedType('user', users, user)
                  ).attributes.office_type
                }}</base-badge>
              </div>
            </td>
            <td :class="[classes.td]">
              <router-link
                :to="`users/tambah-user/${user.attributes.user_id}`"
                class="underline"
                >Edit</router-link
              >
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <user-detail-modal
      :visible="detailModal.visible"
      :reload="detailModal.reload"
      :user-id="detailModal.userId"
      @close="onCloseDetail"
      @edit-password="onEditPassword"
    />
    <user-edit-password-modal
      :visible="editPasswordModal.visible"
      :user-id="editPasswordModal.userId"
      @close="onCloseEditPassword"
    />

    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import UserDetailModal from '@/components/user/user-detail-modal.vue';
import UserEditPasswordModal from '@/components/user/user-edit-password-modal.vue';
import OfficeSelectSearch from '@/components/office/office-select-search.vue';
import RoleSelectSearch from '@/components/role/role-select-search.vue';

export default {
  mixins: [requestMixin],
  components: {
    UserDetailModal,
    UserEditPasswordModal,
    OfficeSelectSearch,
    RoleSelectSearch,
  },
  data() {
    return {
      loading: false,
      users: {
        data: [],
        meta: {
          page: {},
        },
      },
      filter: {
        search: null,
        office: {
          attributes: {},
        },
        role: {
          id: null,
        },
      },
      detailModal: {
        visible: false,
        reload: true,
        userId: null,
      },
      editPasswordModal: {
        visible: false,
        userId: null,
      },
    };
  },
  computed: {
    columns() {
      return [
        { id: 'name', name: 'Nama' },
        { id: 'role', name: 'Role' },
        { id: 'office_name', name: 'Nama Office' },
        { id: 'actions', name: 'Aksi' },
      ];
    },
  },
  methods: {
    onPageChanged(page) {
      this.loadUsers({
        ...page,
      });
    },
    onFilter() {
      this.loadUsers();
    },
    onOpenDetail(user) {
      this.detailModal.userId = user.attributes.user_id;
      this.detailModal.reload = true;
      this.detailModal.visible = true;
    },
    onCloseDetail() {
      this.detailModal.visible = false;
    },
    onEditPassword() {
      this.detailModal.visible = false;

      this.editPasswordModal.userId = this.detailModal.userId;
      this.editPasswordModal.visible = true;
    },
    onCloseEditPassword() {
      this.editPasswordModal.visible = false;

      this.detailModal.reload = false;
      this.detailModal.visible = true;
    },
    async loadUsers(params) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/users', {
        params: {
          'page[limit]': 5,
          'filter[search]': this.filter.search,
          'filter[office_uuid]': this.filter.office.attributes
            ? this.filter.office.attributes.office_id
            : null,
          'filter[current_role_uuid]': this.filter.role.id
            ? this.filter.role.id
            : null,
          include: 'user.office',
          'fields[simple-users]': 'name,email,user_id,user',
          'fields[users]': 'current_role,office',
          'fields[offices]': 'name,office_type',
          ...params,
        },
      });

      if (!error) {
        this.users = res;
      }

      this.loading = false;
    },
  },
  created() {
    this.loadUsers();
  },
};
</script>
