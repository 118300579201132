<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex justify-end gap-x-2">
          <base-search
            placeholder="Cari Kode"
            v-model="search"
            v-on:input="onSearch"
          />
          <base-button @click="onDownload" :loading="exportMitraLoading">
            <Icon
              v-if="!exportMitraLoading"
              icon="heroicons:arrow-down-tray-20-solid"
              class="h-5 w-5"
            />
          </base-button>
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <MitraUsahaTable
              @click-row="onClickDetailOffice"
              @page-change="onPageChange"
              @promote="onPromote"
            />
          </div>
        </div>
      </div>
    </div>
    <Modal
      :showing="openDetail"
      v-if="getOffice.data?.relationships"
      @close="openDetail = false"
      :showClose="openDetail"
      :backgroundClose="true"
      size="max-w-6xl"
    >
      <div class="flex justify-between px-4 py-5 sm:px-6">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Detail Kantor
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Berdasarkan data yang telah dibuat
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-52" />
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div class="my-4 flex items-center justify-between">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Biodata Mitra Usaha
          </h3>
          <toggle-button
            v-model="isOfficeSelectedSuspended"
            :labels="{
              checked: 'Aktif',
              unchecked: 'Non Aktif',
            }"
            :width="isOfficeSelectedSuspended ? 60 : 80"
            v-on:change="onActiveChange"
          />
        </div>
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6">
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">
              Kode Tetap / Sementara Mitra Usaha
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data?.attributes.code }} /
              {{ getOffice.data?.attributes.temp_code }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Nama Mitra Usaha</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data?.attributes.name }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">No. KTP</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data?.attributes.ktp_number ?? '-' }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Tanggal lahir</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                getOffice.data?.attributes.birthdate
                  ? dayjs(getOffice.data?.attributes.birthdate).format('L')
                  : 'Tanggal lahir belum diatur'
              }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">No. Hp</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data?.attributes.phone || 'Tidak ada No. HP' }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Email</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data?.attributes.email || 'Tidak ada Email' }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Alamat Mitra Usaha</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data?.attributes.address || 'Alamat belum diatur' }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Pendidikan Terakhir</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                getOffice.data.relationships?.education
                  ? getRelationships(
                      getOffice,
                      getOffice.data.relationships.education?.data?.id
                    )?.attributes.name || '-'
                  : null
              }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Pekerjaan Terakhir</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                getOffice.data?.relationships?.profession
                  ? getRelationships(
                      getOffice,
                      getOffice.data.relationships.profession.data?.id
                    )?.attributes.name || '-'
                  : null
              }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data?.attributes?.createdAt | formatDate }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Terakhir Diperbarui</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data?.attributes?.updatedAt | formatDate }}
            </dd>
          </div>

          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">No Uplines</dt>
            <dd
              v-if="getOffice.data?.attributes.upline_histories"
              class="mt-1 text-sm text-gray-900"
            >
              <BaseBadge
                v-for="(rel, index) in getOffice.data?.attributes
                  .upline_histories"
                :key="index"
              >
                {{ rel.code }}
              </BaseBadge>
            </dd>
            <span v-else> - </span>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Nama Uplines</dt>
            <dd
              v-if="getOffice.data?.attributes.upline_histories"
              class="mt-1 text-sm text-gray-900"
            >
              <BaseBadge
                v-for="(rel, index) in getOffice.data?.attributes
                  .upline_histories"
                :key="index"
              >
                {{ rel.name }}
              </BaseBadge>
            </dd>
            <span v-else> - </span>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Jenis Kelamin</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.sex">
                {{
                  getOffice.data?.attributes.sex == 'male'
                    ? 'Laki-laki'
                    : getOffice.data?.attributes.sex == 'female'
                    ? 'Perempuan'
                    : getOffice.data?.attributes.sex
                }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>

          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Kode Pos</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.postal_code">
                {{ getOffice.data?.attributes.postal_code }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Area</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.relationships.area.data?.id">
                {{
                  getRelationships(
                    getOffice,
                    getOffice.data?.relationships.area.data?.id
                  ).attributes.name
                }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Nama Rekening</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.bank_account_name">
                {{ getOffice.data.attributes.bank_account_name }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">No. Rekening</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.bank_account_number">
                {{ getOffice.data.attributes.bank_account_number }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Nama Bank</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.bank_name">
                {{ getOffice.data.attributes.bank_name }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Cabang Bank</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.bank_branch_name">
                {{ getOffice.data.attributes.bank_branch_name }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Nama NPWP</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.npwp_name">
                {{ getOffice.data.attributes.npwp_name }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">No NPWP</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.npwp_number">
                {{ getOffice.data.attributes.npwp_number }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">No Akun GL</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.no_account_gl">
                {{ getOffice.data.attributes.no_account_gl }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Status Pernikahan</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <BaseBadge v-if="getOffice.data?.attributes.marital_status">
                {{ getOffice.data.attributes.marital_status }}
              </BaseBadge>
              <span v-else>-</span>
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">
              Nama / Tanggal Lahir Pasangan
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data?.attributes.spouse_name ?? '-' }}
              {{
                getOffice.data?.attributes.spouse_birthdate
                  ? dayjs(getOffice.data?.attributes.spouse_birthdate).format(
                      'L'
                    )
                  : ''
              }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">
              Nama / Tanggal Lahir Pewaris
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getOffice.data?.attributes.devisor_name ?? '-' }}
              {{
                getOffice.data?.attributes.devisor_birthdate
                  ? dayjs(getOffice.data?.attributes.devisor_birthdate).format(
                      'L'
                    )
                  : ''
              }}
            </dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm font-bold text-gray-700">Wilayah Harga</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                getRelationships(
                  getOffice,
                  getOffice.data?.relationships.area.data?.id
                )?.attributes.name ?? '-'
              }}
              ({{
                getRelationships(
                  getOffice,
                  getOffice.data?.relationships.area.data?.id
                )?.attributes.code ?? ''
              }})
            </dd>
          </div>
        </dl>
      </div>
      <section class="px-6">
        <h3 class="mb-4 text-lg font-bold leading-6 text-gray-900">
          Daftar User di Kantor Ini
        </h3>
        <Datatable
          :total="officeData.users.meta.page.total"
          :perPage="officeData.users.meta.page.perPage"
          :currentPage="officeData.users.meta.page.currentPage"
          :paginated="false"
        >
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Nama
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Email
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Role
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Status
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody v-if="officeData.users.data.length > 0">
              <tr
                class="bg-white hover:bg-green-100"
                v-for="(data, dataIdx) in officeData.users.data"
                :key="data.id"
                :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  {{ data.attributes.name }}
                </td>
                <td
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  {{ data.attributes.email }}
                </td>
                <td
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  {{ data.attributes.current_role }}
                </td>
                <td
                  class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  <toggle-button
                    :value="!data.attributes.is_suspended"
                    :labels="{
                      checked: 'Aktif',
                      unchecked: 'Non Aktif',
                    }"
                    :width="data.attributes.is_suspended ? 60 : 80"
                    v-on:change="onActiveUserChange(data)"
                  />
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="bg-white">
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  colspan="4"
                >
                  Data tidak ditemukan
                </td>
              </tr>
            </tbody>
          </template>
        </Datatable>
      </section>
      <div class="mx-6 mt-4 flex justify-between space-x-4">
        <base-button
          color="white"
          @click="() => this.$router.push(`member/${getOffice.data.id}`)"
        >
          <Icon class="h-5 w-5" icon="heroicons:pencil-square" />
          Edit
        </base-button>
        <base-button @click="openDetail = !openDetail" type="button">
          Tutup
        </base-button>
      </div>
    </Modal>

    <transition name="fade">
      <div
        v-if="modal === 'success'"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
          >
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
              >
                <svg
                  class="h-6 w-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <slot name="heading"> Data Berhasil disimpan </slot>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <div class="flex items-center gap-2">
                <button
                  @click="modal = null"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                >
                  Selesai
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="modal === 'error'"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
          >
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <slot name="heading">
                  {{ getError }}
                </slot>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <div class="flex items-center gap-2">
                <button
                  @click="modal = null"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                >
                  Selesai
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- MODAL VALIDASI KEMASAN-->
    <Modal
      :showing="modal === 'promoteToStockist'"
      @close="modal = null0"
      :showClose="modal === 'promoteToStockist'"
      :backgroundClose="true"
      size="max-w-xl"
    >
      <form @submit.prevent="onSubmitPromote">
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg font-medium leading-6 text-gray-900"
              id="modal-title"
            >
              Promosi Kantor
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                Lengkapi form dibawah ini untuk melakukan promosi
              </p>
            </div>
          </div>
          <div class="my-4 space-y-3">
            <div class="space-y-3">
              <div class="field-inset-default w-full">
                <label for="code" class="block text-xs font-bold text-gray-700">
                  Kode Kantor
                </label>
                <input
                  v-model="promote.code"
                  type="text"
                  name="code"
                  id="code"
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan kode kantor (kode kantor harus berbeda)"
                />
              </div>
              <div class="field-inset-default w-full">
                <label
                  for="email"
                  class="block text-xs font-bold text-gray-700"
                >
                  Email
                </label>
                <input
                  v-model="promote.email"
                  type="email"
                  name="email"
                  id="email"
                  class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan email baru (contoh: memberbaru@naturalnusantara.co.id)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
          <button
            type="submit"
            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            Promosi
          </button>
          <button
            @click="modal = null"
            type="button"
            class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          >
            Tutup
          </button>
        </div>
      </form>
    </Modal>

    <transition
      name="customFade"
      enter-active-class="fadeIn"
      leave-active-class="fadeOut"
    >
      <div
        v-if="modal === 'promoteToSC'"
        style="animation-duration: 0.3s"
        class="fixed inset-0 z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="mx-4 flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <form @submit.prevent="promoteStockistToSC">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3
                    class="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Promosi Kantor
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Lengkapi form dibawah ini untuk melakukan promosi
                    </p>
                  </div>
                </div>
                <div class="my-4 space-y-3">
                  <div class="space-y-3">
                    <div class="field-inset-default w-full">
                      <label
                        for="code"
                        class="block text-xs font-bold text-gray-700"
                      >
                        Kode Kantor
                      </label>
                      <input
                        v-model="promote.code"
                        type="text"
                        name="code"
                        id="code"
                        class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        placeholder="Masukkan kode kantor (kode kantor harus berbeda)"
                      />
                    </div>
                    <div class="field-inset-default w-full">
                      <label
                        for="email"
                        class="block text-xs font-bold text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        v-model="promote.email"
                        type="email"
                        name="email"
                        id="email"
                        class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        placeholder="Masukkan email baru (contoh: memberbaru@naturalnusantara.co.id)"
                      />
                    </div>
                    <div class="field-inset-default w-full">
                      <label
                        for="no_account_gl"
                        class="block text-xs font-bold text-gray-700"
                      >
                        No Account GL
                      </label>
                      <input
                        v-model="promote.no_account_gl"
                        type="text"
                        name="no_account_gl"
                        id="no_account_gl"
                        class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        placeholder="Masukkan No Account GL baru (contoh: 2342ABFDS"
                      />
                    </div>
                    <div class="field-inset-default w-full">
                      <label
                        for="cost_center"
                        class="block text-xs font-bold text-gray-700"
                      >
                        Cost Center
                      </label>
                      <input
                        v-model="promote.cost_center"
                        type="text"
                        name="cost_center"
                        id="cost_center"
                        class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        placeholder="Masukkan No Cost Center (wajib)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                <base-button type="submit"> Promosi </base-button>
                <base-button @click="modal = null" color="white" type="button">
                  Tutup
                </base-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>
    <loading v-if="getLoading || isLoading || getSimpleOfficeLoading" />
  </div>
</template>

<script>
import BaseSearch from '@/components/base/Search.vue';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import { mapActions, mapGetters } from 'vuex';
import { ToggleButton } from 'vue-js-toggle-button';
import Modal from '@/components/base/BaseModal';
import dayjs from 'dayjs';
import ApiService from '@/services/api.service';
import BaseBadge from '@/components/base/BaseBadge.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import { downloadFileUrl } from '@/services/utils.service';
import MitraUsahaTable from '@/components/mitra-usaha/mitra-usaha-table.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    ToggleButton,
    Modal,
    BaseBadge,
    BaseButton,
    BaseSearch,
    MitraUsahaTable,
  },
  data() {
    return {
      search: null,
      isLoading: false,
      isOfficeSelectedSuspended: false,
      office_type: 'member',
      modal: null,
      officeSelected: null,
      promote: {
        code: null,
        email: null,
        no_account_gl: null,
        cost_center: null,
      },
      openDetail: false,
      officeData: {
        id: '',
        office_type: '',
        code: '',
        deposit_balance: '',
        deposit_multiplier: '',
        name: '',
        ktp_number: '',
        birthdate: '',
        address: '',
        address2: '',
        phone: '',
        marital_status: '',
        spouse_name: '',
        spouse_birthdate: '',
        devisor_name: '',
        devisor_birthdate: '',
        bank_account_number: '',
        bank_account_name: '',
        bank_name: '',
        bank_branch_name: '',
        npwp_number: '',
        npwp_name: '',
        npwp_address: '',
        npwp_address2: '',
        users: {
          data: [],
          meta: {
            page: {
              total: '',
              perPage: '',
              currentPage: '',
            },
          },
        },
        address_details: [
          {
            office: '',
            province: '',
            city: '',
            district: '',
            village: '',
            detail: '',
          },
        ],
        is_suspended: '',
        is_promoted: '',
      },
      editPasswordForm: {
        password: '',
        password_confirmation: '',
      },
      exportMitraLoading: false,
    };
  },
  created() {
    this.loadMember();

    const handleEscape = () => {
      this.openDetail = this.success = this.failed = false;
      this.search = '';
    };
    this.setEscape(handleEscape);

    this.listenToExportMemberEvent();
  },
  computed: {
    ...mapGetters({
      getOffice: 'offices/getOffice',
      getLoading: 'offices/getLoading',
      getError: 'offices/getError',
      getSimpleOffices: 'simple_offices/getSimpleOffices',
      getSimpleOfficeLoading: 'simple_offices/getLoading',
      getUser: 'auth/getUser',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOfficeById: 'offices/fetchOfficeById',
      updateOffice: 'offices/updateOffice',
      promoteToStockist: 'offices/promoteToStockist',
      downloadMitra: 'offices/downloadMitra',
      exportMitra: 'offices/exportMitra',
      updateUser: 'users/updateUser',
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
      createAlert: 'alert/createAlert',
    }),
    async onClickDetailOffice(simpleOffice) {
      this.isLoading = true;

      await Promise.all([
        this.loadDetailMemberUser(simpleOffice.attributes.office_id),
        this.fetchOfficeById({
          office_id: simpleOffice.attributes.office_id,
          params: {
            include: 'education,profession,area',
            'fields[offices]':
              'bank_account_name,bank_account_number,bank_name,bank_branch_name,npwp_name,npwp_number,no_account_gl,marital_status,is_suspended,code,temp_code,name,ktp_number,phone,email,address,education,profession,createdAt,updatedAt,upline_histories,sex,spouse_name,spouse_birthdate,devisor_name,devisor_birthdate,area',
          },
        }),
      ]);

      this.isOfficeSelectedSuspended =
        this.getOffice.data.attributes.is_suspended;

      this.isLoading = false;
      this.openDetail = true;
    },
    async onDownload() {
      this.exportMitraLoading = true;

      await this.exportMitra();
    },
    onPageChange(page) {
      this.loadMember(page);
    },
    onSearch: debounce(function () {
      this.loadMember();
    }, 300),
    onPromote({ data, type }) {
      this.modal = type;
      this.officeSelected = this.getOfficeIncluded(data);
    },
    onSubmitPromote() {
      const payload = {
        data: {
          type: 'offices',
          attributes: {
            code: this.promote.code,
            email: this.promote.email,
          },
        },
      };

      this.promoteToStockist({
        payload,
        id: this.officeSelected.id,
      }).then((response) => {
        if (response) {
          this.modal = 'success';
          this.search = '';

          this.loadMember();
        } else {
          this.modal = 'error';
        }
      });
    },
    onActiveChange() {
      const payload = {
        data: {
          type: 'offices',
          id: this.getOffice.data?.id,
          attributes: {
            is_suspended: !this.isOfficeSelectedSuspended,
          },
        },
      };

      this.updateOffice(payload).then((response) => {
        if (response) {
          this.success = true;
          this.search = '';

          this.loadMember();
        } else {
          this.failed = true;
        }
      });
    },
    onActiveUserChange(user) {
      const payload = {
        data: {
          type: 'users',
          id: user.id,
          attributes: {
            is_suspended: !user.attributes.is_suspended,
          },
        },
      };

      this.updateUser(payload);
    },
    async loadMember(params = {}) {
      return await this.fetchSimpleOffices({
        ...params,
        'filter[search]': this.search,
        'filter[office_category_id]': 5,
        'page[limit]': 5,
        include: 'office',
        'fields[simple-offices]': 'code,name,office,office_id',
        'fields[offices]':
          'phone,is_active,is_promoted,address,email,bank_account_number,promoted_by,upline_histories,downline_histories',
      });
    },
    async loadDetailMemberUser(officeId) {
      const response = await ApiService.get(`api/v1/offices/${officeId}/users`);

      this.officeData.users = response.data;
    },
    getOfficeIncluded(simpleOffice) {
      return this.getSingleIncluded(
        this.getSimpleOffices,
        simpleOffice.relationships.office.data.id
      );
    },
    getRelationships(resource, id) {
      return resource.included?.find((it) => it.id == id);
    },
    listenToExportMemberEvent() {
      const exportMemberDbfChannel = this.$pusher.subscribe(
        `private-ExportMemberDbf.${this.getUser.id}`
      );

      exportMemberDbfChannel.bind('ExportMemberDbfStatusUpdated', (e) => {
        if (e.status === 'processing') {
          this.exportMitraLoading = true;
        } else if (e.status === 'failed') {
          this.exportMitraLoading = false;

          this.createAlert({ data: e.message, status: 'error' });
        } else if (e.status === 'finished') {
          this.exportMitraLoading = false;

          downloadFileUrl(e.file_url);
        }
      });
    },
    stopListenToExportMemberEvent() {
      this.$pusher.unsubscribe(`private-ExportMemberDbf.${this.getUser.id}`);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.stopListenToExportMemberEvent();

    next();
  },
};
</script>
